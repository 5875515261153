



































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { dispatchreadAppsForUser } from '@/store/devs/actions';
import { dispatchGetUsers } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'AppCode',
      sortable: true,
      value: 'app_code',
      align: 'left',
    },
    {
      text: 'Active',
      sortable: true,
      value: 'is_active',
      align: 'left',
    },
    {
      text: 'Live',
      sortable: true,
      value: 'isLive',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: false,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];

  private listofapps = [];

  public async mounted() {
    this.getapps();
  }

  private async getapps() {
    const response = await dispatchreadAppsForUser(this.$store);
    if (response && response?.length > 0) {
      this.listofapps = response;
    }
  }

}
